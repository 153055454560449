@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,400;var(--black2)0,500;1,700&family=Roboto:ital,wght@0,300;0,400;1,400;1,500&display=swap');

:root {
    --green: #0C780C;
    --green2: #005A00;
    --green3: #003900;
    --green5: #209220;
    --green6: #3EAA3E;
    --orange: #EA8D51;
    --orange2: #FFC9A6;
    --orange3: #FFAF7B;
    --orange4: #D66E2B;
    --orange5: #A84E14;
    --orange6: #C76400;
    --orange7: #FFF2EA;
    --dark: #1F1F28;
    --dark2: #33333C;
    --dark3: #15162D;
    --dark4: #25233A;
    --grey: #8F8F94;
    --grey2: #F9F9FD;
    --grey3: #EAEAEA;
    --grey3_light: #EAEAEA22;
    --grey4: #8F8F94;
    --grey5: #566BA0;
    --white: #ffffff;
    --white_transparent: #ffffff22;
    --off_white: #FAFBFF;
    --black: #000000;
    --black_transparent: #00000022;
    --black_transparent2: #00000055;
    --black2: #020202;
    --black3: #62666A;
}

a {
    color: unset;
    text-decoration: unset;
}

.izimonie_form {
    max-width: 400px;
    margin: auto
}

.izimonie_form .fieldbox {
    width: 100%;
    margin-top: 20px;
}

.izimonie_form label .label_icon.important {
    height: 10px;
    width: 10px;
    background: url('icons/asterisks.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.react-tel-input .form-control {
    width: calc(100% - 50px) !important;
    margin-left: 50px !important;
}

.izimonie_form label .label_icon.check {
    height: 20px;
    width: 20px;
    background: url('icons/check.svg');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.izimonie_form .fieldbox label {
    margin-bottom: 5px;
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-transform: capitalize;
    color: var(--dark4);
    font-family: 'roboto', sans-serif;
    cursor: pointer;
    display: flex;
}

.izimonie_form .fieldbox input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid var(--dark4);

}

.izimonie_form .fieldbox textarea {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid var(--dark4);
    padding: 10px;
    font-size: 14px;
}


body,
html {
    padding: 0;
    margin: 0;
    border: none;
    font-family: 'roboto', sans-serif;
}

.main {}

.comtainer {
    width: 85%;
    margin: auto;
}

.header {
    width: 100%;
    height: 77px;
    background: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 13px 0;
    position: relative;
}

.header>.container {
    display: flex;
    justify-content: space-between;

}

.header .logo {
    width: 178px;
    height: 50px;
    background: url('./images/IziMonie.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0px 20px;

}

.nav {
    width: 350px;
    display: flex;
    justify-content: space-between;
    padding: 13px;
    position: unset;

}

.nav a {
    color: unset;
    text-decoration: unset;
}

.nav_item {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-transform: uppercase;
    color: var(--dark4);
    font-family: 'roboto', sans-serif;
    cursor: pointer
}

.btn {
    width: 100%;
    max-width: 199px;
    height: 54px;
    background: var(--green);
    border-radius: 10px;
    color: var(--white);
    line-height: 40px;
    text-align: center;
    font-family: 'roboto', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 0;
}

.btn_dumb {
    background: var(--grey3) !important;
}

.btn a {
    width: 100%;
    height: 100%;
}

.btn a:hover {
    color: unset;
    text-decoration: unset;

}

.btn:hover,
.btn:active {
    background: var(--green5);
    color: var(--white);
}

.top_btn {}

.btn_bland {
    background: none;
    outline: none;
    width: auto !important;
    max-width: unset;
    text-align: left;
    padding: 0;
    margin: 0;
    color: var(--orange)
}

.btn_bland:hover,
.btn_bland:active {
    background: none;
    outline: none;
    width: auto !important;
    max-width: unset;
    text-align: left;
    padding: 0;
    color: var(--orange2)
}

.hero {
    width: 100%;
    height: auto;
    background: var(--orange);
    background: url('./images/hero.png');
    background-size: cover;
    background-position: center -50px;
    background-repeat: no-repeat;
    padding: 138px 0 200px;
}


.hero_title {
    max-width: 579px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 52px;
    /* or 108% */
    text-align: center;
    color: var(--white);
    margin: auto;
}

.hero_subtitle {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: var(--white);
    margin: 18px auto;
    max-width: 415px;
}

.hero3 {
    min-height: 206px;
    background: var(--dark) !important;
    padding: 68px 0 0;
}

.hero3 .hero_title {

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: var(--orange6);
    text-align: left !important;
    width: 100% !important;
    max-width: 100% !important;
}

.hero3 .hero_subtitle {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--white);
    text-align: left !important;
    width: 100% !important;
    max-width: 287px !important;
    margin: 12px 0;

}

.hero_buttons {
    max-width: 540px;
    height: 64px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.hero_buttons .btn {
    width: calc(50% - 10px) !important;
    max-width: 270px;
}

.row {}

.col-sm-6 {}

.btn_solid {
    background: var(--orange);
}

.btn_solid:hover,
.btn_solid:active {
    background: var(--orange4);
}

.btn-light {
    background: none;
    border: solid 0.1px var(--orange);
    color: var(--orange)
}

.btn-light:hover {
    background: none;
    border: solid 0.1px var(--orange3);
    color: var(--orange3)
}

.section_light {
    width: 100%;
    min-height: 500px;
    height: auto;
    left: 0px;
    top: 671px;
    background: var(--grey2);
    padding: 78px 0 168px;

}

.section_white {
    background: var(--white);
}

.section_curved {
    margin-top: -100px;
    border-radius: 100px 100px 0px 0px;
}

section .container {
    width: 85%;
    margin: auto
}

.logos_section {}


.logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
}

.logos .logo {
    width: 178px;
    height: 58px;
    background: url('./images/IziMonie.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .idh {
    width: 178px;
    height: 58px;
    background: url('./images/idh-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .mercy_corps {
    width: 178px;
    height: 58px;
    background: url('./images/mercycorps.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .ciat {
    width: 178px;
    height: 58px;
    background: url('./images/ciat.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .cropnuts {
    width: 178px;
    height: 58px;
    background: url('./images/cropnuts.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .planet {
    width: 178px;
    height: 58px;
    background: url('./images/planet.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.logos .ocp {
    width: 178px;
    height: 58px;
    background: url('./images/ocp.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 24px 42px;
}

.section_title {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    text-transform: uppercase;

    color: var(--orange6);
    width: 100%;
    text-align: center;
}

.section_subtitle {
    max-width: 551px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: var(--black2);
    margin: auto;
}

.section_text {
    max-width: 551px;
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--dark4);
    margin: auto;
    padding-top: 20px;
}

.whoarewe_section {}

.section_body {}

.image_sliders {
    width: 100%;
    margin: 61px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto !important;
}

.image_sliders .image {
    width: calc(20% - 1px);
    height: auto !important;
    aspect-ratio: 1 / 1;
}

.image_sliders .image_1 {
    background: url('./images/image1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image_sliders .image_2 {
    background: url('./images/image2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image_sliders .image_3 {
    background: url('./images/image3.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image_sliders .image_4 {
    background: url('./images/image4.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image_sliders .image_5 {
    background: url('./images/image5.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.section_dark,
.section_dark2 {
    background: var(--dark);
    width: 100%;
    min-height: 500px;
    height: auto;
    left: 0px;
    top: 671px;
    padding: 78px 0 168px;
}


.section_dark .section_subtitle,
.section_dark2 .section_subtitle {
    color: var(--white);
}

.section_dark .section_text,
.section_dark2 .section_text {
    color: var(--white);
}

.toggle_box {
    display: flex;
    justify-content: stretch;
}

.toggle_item {
    width: 100%;
    border-bottom: 1px solid var(--white);
    margin: 100px 0 76px;
    cursor: pointer;
}

.toggle_item .index {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    text-align: center;
    width: 100%;
    color: var(--white);
}



.toggle_item .text {
    text-align: center;
    color: var(--grey4);
    margin-bottom: 10px;
    font-family: 'roboto', sans-serif;
}

.toggle_item .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: var(--grey4);
}



.toggle_item.active {
    border-bottom: solid 1px var(--orange);
}

.toggle_item.active .title {
    color: var(--orange)
}

.toggle_item.active .text {
    color: var(--orange);
}

.toggle_item:hover,
.toggle_item:active {
    border-bottom: solid 1px var(--orange3);
}

.toggle_item:hover .title,
.toggle_item:active .title {
    color: var(--orange3)
}

.toggle_item:hover .text,
.toggle_item:active .text {
    color: var(--orange3);
}

.showcase {}

.showcase_box {
    height: auto;
    min-height: 540px;
}

.showcase_image {
    background: url('./images/fruit.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    border-radius: 30px;
    aspect-ratio: 1 / 1.088073394495413;
}

.showcase_image.food_security {
    background: url('./images/foodSecurity.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    border-radius: 30px;
    aspect-ratio: 1 / 1.088073394495413;
}

.showcase_image.climatechange {
    background: url('./images/climateChange.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    border-radius: 30px;
    aspect-ratio: 1 / 1.088073394495413;
}

.showcase_image.biodiversity {
    background: url('./images/bioDiversity.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    border-radius: 30px;
    aspect-ratio: 1 / 1.088073394495413;
}

.food_security {}

.showcase_details {
    padding: 84px;
}

.showcase_details .buttons {
    /* padding: 84px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.showcase_details .buttons .btn {
    width: calc(50% - 10px);
    max-width: unset;
}



.showcase_details .icon {
    width: 80px;
    height: 80px;
    background: var(--white);
    border-radius: 50%;
    margin-bottom: 24px;
}

.showcase_details .icon.cloud {
    background: url('./images/cloud-lightning.svg') var(--white);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}


.showcase_details .icon.trees {
    background: url('./images/tree.svg') var(--white);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.showcase_details .icon.bag_tick {
    background: url('./images/bag-tick.svg') var(--white);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}


.showcase_details .title {
    text-align: left !important;
}

.showcase_details .text {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */
    text-align: left;
    color: var(--white);
    margin-bottom: 33px;
    margin-top: 12px;
}


.section_dark2 {
    background: var(--dark2);
    width: 100%;
    min-height: 500px;
    height: auto;
    left: 0px;
    top: 671px;
    padding: 78px 0 98px;
}

.showcase-details {}

.section_text {}

.features_list {
    width: 100%;
    height: auto;
    background: var(--grey3_light);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 60px 0 0px;
}


.feature_item {
    width: calc(50% - 0.5px);
    height: 400px;
    background: var(--dark2);
    padding: 40px 61px
}

.feature_item.no_top_margin {
    margin-bottom: 1px;
}

.feature_item .title,
.showcase_details .title,
.footer_top .title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: var(--orange);
}

.showcase_details .title,
.footer_top .title {
    color: var(--white);
}

.feature_item .text {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    text-align: center;
    font-family: 'roboto', sans-serif;
    color: var(--white);
    padding-top: 20px;

}

.feature_item .icon {
    width: 95px;
    height: 95px;
    margin: 14px auto;
    background: var(--white_transparent);
    border-radius: 50%;
}


.feature_item .icon.image_processing {
    background: url('./images/image_processing.svg') var(--white_transparent);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
}

.feature_item .icon.anomaly_detection {
    background: url('./images/anomaly.svg') var(--white_transparent);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
}

.feature_item .icon.decisioning_analytics {
    background: url('./images/descision.svg') var(--white_transparent);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
}

.feature_item .icon.insights {
    background: url('./images/insights_icon.svg') var(--white_transparent);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
}

.footer {
    background: var(--dark3);
    padding: 48px 0;
}

.footer_top {
    display: flex;
    justify-content: space-between;
}

.footer_top .footer_intro {
    width: calc(50% - 100px);
}

.footer_buttons {
    display: flex;
    justify-content: space-between;
    width: calc(50% + 70px);
}

.footer .subtitle {
    color: var(--white);
    text-align: left;
    font-family: 'roboto', sans-serif;
    font-weight: 300;
}

.footer_top .title {
    text-align: left;
    margin-bottom: 20px;
}

.footer .text {
    color: var(--white);
    text-align: left;
}

.footer_buttons .btn {
    width: calc(50% - 5px);
    max-width: calc(50% - 5px);
}

.footer_bottom {
    padding: 64px 0 30px;
    display: flex;
    justify-content: stretch;

}

.footer_bottom .row {
    flex-wrap: unset;
    justify-content: stretch !important;
    width: 100%;
}

.footer_bottom_side {
    width: 100%;
    padding: 0;
    margin: 0
}

.footer_bottom .logo {
    background: url('./images/izimonie_white.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 178px;
    height: 50px;
    margin-right: 161px;

}

.col-sm-4 {}

.col-sm-8 {}

.footer_list {
    color: var(--white);
}

.footer_list .title {
    color: var(--white);
}

.footer_bottom .title {
    color: var(--white);
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 30px;
    /* identical to box height, or 214% */

    text-transform: uppercase;
    letter-spacing: 5px;
    /* text-decoration: underline; */

    color: var(--white);

    opacity: 0.3;
    margin-bottom: 18px;
}

.footer_item {
    color: var(--white);
    font-family: 'roboto', sans-serif;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 300;
    font-size: 13px;
}

.footer_item:hover {
    opacity: 0.7
}

.social_media_icons {}

.footer_bottom .fieldset {
    color: var(--white);
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: 0.4px solid var(--white);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}


.footer_bottom .fieldset input {
    background: none;
    outline: none;
    border: none;
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: var(--white);
    width: calc(100%);
    margin-right: 10px;
    padding: 0 20px;

}

.social_media_icons {
    display: flex;
}

.social_media_icons .icon {
    background: var(--white);
    width: 25px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-right: 17px;
}

.social_media_icons .icon.twitter {
    background: url('./images/u_twitter.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.facebook {
    background: url('./images/u_facebook.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.linkedIn {
    background: url('./images/u_linkedin.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.instagram {
    background: url('./images/u_instagram-alt.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.social_media_icons .icon.youtube {
    background: url('./images/u_youtube.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.newsletter_text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    /* identical to box height, or 250% */


    color: var(--white);
}

.footer_bottom .fieldset .btn {
    font-family: 'roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: var(--orange6);
    background: none;
    outline: none;
    width: 100px;
    border: none;
    height: 100%;
}

.footer_bottom .text {
    font-family: 'Rro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    /* identical to box height, or 250% */
    color: var(--white);
}

.footnotes {
    color: var(--white);
    display: flex;
    justify-content: space-between;
}

.copyright {}

.others {
    display: flex;
    justify-content: space-between;
    font-family: 'roboto', sans-serif;
    font-weight: 200;
    font-size: 14px;
    padding: 0 27px
}


.show_large_only {
    display: block;
}

.dont_show_large {
    display: none;
}

.anchor {

    display: none;

}

.career_openings {}

.career_openings .career_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.career_top .title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: var(--black2);
}

.career_top .filters {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.career_top .filters .fieldset {
    width: calc(50% - 7.5px);
    height: 40px;
    background: var(--white);
    border: 0.4px solid var(--black_transparent);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.career_top .filters .fieldset select {
    background: none;
    width: 100%;
    height: 100%;
    border: none;
    color: var(--black_transparent2);
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    padding: 0 11px;
}

.career_top .filters .fieldset input {
    width: calc(100% - 30px);
    height: 100%;
    outline: none;
    border: none;
    color: var(--black_transparent2);
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    padding: 0 11px;
}

.career_top .filters .fieldset .icon {
    width: 18px;
    height: 18px;
}

.career_top .filters .fieldset .icon.search {
    background: url('./images/fi_search.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 11px;
    cursor: pointer;
}

.career_body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
}

.job_item {
    width: 502px;
    height: 108px;
    background: var(--orange7);
    border-radius: 10px;
    width: calc(50% - 7.5px);
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.job_item .title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    color: var(--orange6);
}

.job_item .text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    max-width: 287px;

    color: var(--black3);
}

.job_item .icon {
    width: 18px;
    height: 18px;
    background: url('./images/fi_chevron-down.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section_side {
    padding-right: 50px;
}

.section_side .title {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-transform: uppercase;

    color: var(--black);

    opacity: 0.4;
}

.section_side .text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
    /* or 200% */
    color: var(--black2);
    margin-bottom: 50px;
}

.section_pop_form {
    background: var(--off_white);
    margin-top: -190px;
    padding: 35px 0;
}

.section_pop_form .title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    text-align: center !important;
    color: var(--black2);
    margin-bottom: 23px;
}

.section_pop_form .fieldset .input {
    width: 100%;
    height: 65px;
    background: var(--white);
    border: 1px solid rgba(86, 107, 160, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0 18px;
}

.section_pop_form .fieldset .input .filename {
    font-family: 'roboto';
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: var(--grey5);

    opacity: 0.7;
}

.section_pop_form .fieldset .input .btn_bland {
    line-height: 50px;
    width: 150px !important;
    max-width: unsets;
    text-align: right;
}

.section_pop_form .fieldset label {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: var(--grey5);

    opacity: 0.8;
    margin: 20px 0;
}

.section_pop_form .btn_solid {
    margin-top: 41px !important;
    margin: auto;
}

.fadein,
.fadeout {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 1s ease-in-out;
}

.fadein {
    opacity: 1;
}

@media (max-width: 1200px) {
    .hero {
        background-position: center;
    }
}

@media (max-width: 768px) {


    .section_pop_form {
        margin-top: 40px;
    }

    .career_body .job_item {
        width: 100%;

    }

    .career_top {
        flex-wrap: wrap;
        justify-content: unset;
        align-items: flex-start;
        height: auto;
    }

    .career_top .filters {
        width: 100%;
        margin-top: 15px;
    }
}

@media (min-width: 980px) and (max-width: 1100px) {
    .feature_item {
        height: 500px !important;
    }

    .showcase_details .buttons .btn {
        width: calc(50% - 3px);
        max-width: unset;
        font-size: 9px;
        height: 50px;
    }
}

@media (max-width: 980px) {

    .header {
        height: 55px;
        position: fixed;
        top: 0
    }

    .header .logo {
        height: 30px;
        width: 150px;
        position: absolute;
        left: calc(50% - 92.5px);
    }

    .header .container {
        padding: 0 0;
        width: 100%;
        max-width: 100%;
    }

    .nav {
        position: absolute;
        display: block;
        top: 85%;
        right: 0;
        height: auto;
        max-width: 400px;
        width: 100%;
        display: none;
    }

    .nav_item {
        width: 100%;
        border-bottom: var(--black_transparent) solid 1px;
        background: var(--white);
        padding: 10px;
    }

    .anchor {
        width: 24px;
        height: 24px;
        background: url('./images/fi_menu.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 15px;
        top: 15px;
        display: block;
        height: 24px;
        cursor: pointer;
    }

    .hero {
        min-height: 410px !important;
        padding: 65px 0 120px;
        height: auto;
        margin-top: 55px;
    }

    .hero3,
    .hero2 {
        min-height: 200px !important;
        padding: 68px 0px 68px;
    }

    .hero_title {
        font-size: 32px;
        max-width: 90%;
    }

    .hero_subtitle {
        font-size: 15px;
        max-width: 90%;
    }

    .hero_buttons {
        max-width: 90%;
        font-size: 10.0708px;
    }

    .hero_buttons .btn {
        width: calc(50% - 6px);
        max-width: calc(50% - 6px);
        height: 40.28px;
        font-size: 10.0708px;
        line-height: 38px;
        padding: 0;
        border-radius: 6.29423px;
    }

    .section_curved {
        margin-top: -50px;
        border-radius: 50px 50px 0px 0px;
    }

    .section_title {
        font-size: 16px;
    }

    .section_subtitle {
        font-size: 20px;

    }

    .section_text {
        font-size: 14px;
        text-align: center;
    }

    section,
    .section_light,
    .section_dark,
    .section_dark2 {
        padding: 35px 0 98px;
    }

    .logos {
        width: 100%;
        margin-bottom: 60px;
    }

    .logos .logo {
        width: 83px;
        height: 38px;
        margin: 15px 10.5px;
    }

    .image_sliders {
        width: 100%;
        height: auto !important;
        margin: 30px 0 0;
    }

    .image_sliders .image {
        width: calc(20% - 1px);
        height: auto !important;
        aspect-ratio: 1 / 1;
    }

    .showcase_image {
        width: 100%;
        min-height: 323px;
        aspect-ratio: 1 / 0.5;
    }

    .showcase_details {
        width: 100%;
        padding: 41px 0;
    }



    .features_list {
        width: 100% !important;
        padding: 0;
        margin: 60px 0 0px;
    }

    .feature_item {
        width: 100%;
        padding: 0 0 56px;
        height: auto;
    }

    .feature_item .icon {
        width: 62px;
        height: 62px;
    }

    .feature_item.no_top_margin {
        margin-bottom: 0px;
    }

    .feature_item .title {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: var(--orange6);
    }

    .feature_item .text {
        font-family: 'roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: var(--white);
    }

    .footer_buttons {
        margin-top: 27px;
    }

    .footer_top {
        display: block !important;
    }

    .footer_top .footer_intro {
        width: 100%;
        padding-right: 0;
    }

    .footer_buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 0;

    }


    .footer_buttons .btn {
        width: 100%;
        height: 41px;
        border-radius: 6.40625px;
        font-size: 10.25px;
        line-height: 30px;
    }

    .footer_bottom {
        margin: 0;
        height: auto !important;
        max-height: unset;
        display: block;
    }

    .footer_bottom .row {
        display: block !important;
    }


    .footer_bottom_side {
        margin-top: 26px;
    }

    .footnotes {
        border-top: 1px solid var(--white)33;
        padding-top: 10px;
        display: block;
    }

    .footnotes .others {
        width: 100%;
        margin-top: 29px
    }

    .show_large_only {
        display: none
    }

    .dont_show_large {
        display: block;
    }

    .show_on_mobile {
        display: block !important;
    }

    .showcase_details .icon {
        width: 60px;
        height: 60px;
    }

}

@media (max-width: 450px) {
    .showcase_details .buttons .btn {
        width: calc(50% - 3px);
        max-width: unset;
        font-size: 10px;
        height: 50px;
    }

    .hero_buttons .btn {
        width: 100%;
        max-width: 100%;
        height: 40.28px;
        font-size: 10.0708px;
        line-height: 38px;
        padding: 0;
        border-radius: 6.29423px;
    }

    .footer_buttons,
    .hero_buttons {
        display: block;
        flex-wrap: wrap;
    }

    .footer_buttons .btn,
    .hero_buttons .btn {
        width: 100%;
        min-width: 100%;
        height: 41px;
        margin-bottom: 20px;
    }

    .showcase_image {
        aspect-ratio: 1 / 1.087542087542088;
    }
}